<template>
    <!--begin header -->
    <header class="header">

        <!--begin nav -->
        <nav ref="navbarTop" class="navbar navbar-default navbar-fixed-top" :class="{ 'opaque': opaque }">
            
            <!--begin container -->
            <div class="container">
        
                <!--begin navbar -->
                <div class="navbar-header">

                    <button data-target="#navbar-collapse-02" data-toggle="collapse" class="navbar-toggle" type="button">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                        
                    <!--logo -->
                    <router-link to="/" class="navbar-brand" id="logo">
                        <img src="@/assets/images/logo.png" alt="Discover Ohrid">
                        <span>Discover Ohrid</span>                        
                    </router-link>
                    
                    <!-- <a href="/" class="navbar-brand" id="logo">
                        <img src="../assets/images/logo.png" alt="Discover Ohrid">
                        <span>Discover Ohrid</span>
                    </a> -->

                </div>
                        
                <div id="navbar-collapse-02" class="collapse navbar-collapse">

                    <ul class="nav navbar-nav navbar-right">

                        <li>
                            <router-link :to="{ name: 'home', hash: '#home' }">Home</router-link>
                        </li>

                        <li>
                            <router-link :to="{ name: 'home', hash: '#about' }">About</router-link>
                        </li>

                        <li>
                            <router-link :to="{ name: 'home', hash: '#features' }">Features</router-link>
                        </li>

                        <!-- <li>
                            <router-link :to="{ name: 'home', hash: '#sites' }">Sites</router-link>
                        </li> -->

                        <li>
                            <router-link :to="{ name: 'home', hash: '#video' }">Video</router-link>
                        </li>

                        <li>
                            <router-link :to="{ name: 'home', hash: '#book-tour' }">Book Tour</router-link>
                        </li>

                        <!-- <li>
                            <router-link to="book-now">Book Now</router-link>
                        </li> -->

                        <!-- <li><a href="#" @click.prevent="scrollTo('#nature')">Nature</a></li> -->

                        <!-- <li><a href="#" @click.prevent="scrollTo('#adventure')">Adventure</a></li> -->

                        <!-- <li><a href="#" @click.prevent="scrollTo('#hospitality')">Hospitality</a></li> -->

                        <li>
                            <router-link
                                :to="{ name: 'home', hash: '#download-app' }"
                                class="external discover-btn"
                            >
                                Get Started
                            </router-link>
                        </li>


                        <!-- <li><a href="#" @click.prevent="scrollTo('#download-app')" class="external discover-btn">Get Started</a></li> -->
                    
                    </ul>
                </div>
                <!--end navbar -->
                                    
            </div>
            <!--end container -->
            
        </nav>
        <!--end nav -->
        
    </header>
    <!--end header -->
</template>

<script>
    export default {
        props: {
            opaque: {
                type: Boolean,
                default: false
            }
        },

        watch:{
            $route (to, from){
                let navbar = document.getElementById("navbar-collapse-02")
                navbar.classList.remove("collapse");
                navbar.classList.remove("in");
                navbar.classList.add("collapse")
            }
        } 
    }
</script>

<style lang="scss" scoped>
    .navbar-brand {
        display: flex;
        align-items: center;

        img {
            margin-right: 1rem;
            height: 50px;
            width: 50px;
        }
    }
</style>