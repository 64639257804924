import client from "api-client";

export default {
  getCheckpoints({ commit }) {
    return client.fetchCheckpoints().then((checkpoints) => {
      commit("setCheckpoints", checkpoints);
    });
  },

  setProcessing: ({commit}, value) => commit("setProcessing", value),
};
