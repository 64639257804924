<template>
  <transition leave-active-class="duration-200">
    <div
      class="fixed inset-0 overflow-y-auto px-4 py-6 sm:px-0 z-50"
      scroll-region
    >
      <transition
        enter-active-class="ease-out duration-300"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-200"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div class="fixed inset-0 transform transition-all">
          <div class="absolute inset-0 bg-gray-400 opacity-20" />
        </div>
      </transition>

      <transition
        enter-active-class="ease-out duration-300"
        enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-to-class="opacity-100 translate-y-0 sm:scale-100"
        leave-active-class="ease-in duration-200"
        leave-from-class="opacity-100 translate-y-0 sm:scale-100"
        leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div class="z-50 flex justify-center items-center h-full">
          <clip-loader
            color="#6d28d9"
            size="100px"
            :loading="true"
          ></clip-loader>
          <!-- <moon-loader color="#6d28d9" size="100px" :loading="true"></moon-loader> -->
          <!-- <beat-loader color="#6d28d9" size="30px" :loading="true"></beat-loader> -->
          <!-- <ring-loader color="#6d28d9" size="130px" :loading="true"></ring-loader> -->
          <!-- <fade-loader color="#6d28d9" size="100px" :loading="true"></fade-loader> -->
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  components: {
    ClipLoader,
  },
};
</script>

<style scoped>
.fixed {
  position: fixed;
}

.absolute { position: absolute; }

.bg-gray-400 { background-color: rgb(156 163 175); }
.opacity-20	{ opacity: 0.2; }
.duration-300 { transition-duration: 300ms; }
.opacity-0 { opacity: 0; }
.opacity-100 { opacity: 1; }
.ease-in { transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }
.ease-out { transition-timing-function: cubic-bezier(0, 0, 0.2, 1); }
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.overflow-y-auto { overflow-y: auto; }

.flex { display: flex; }
.justify-center	{ justify-content: center; }
.items-center { align-items: center; }
.h-full	{ height: 100%; }
.z-50 { z-index: 9999; }
</style>
