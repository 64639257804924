import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/Home'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
            return window.scrollTo({ 
                top: document.querySelector(to.hash).offsetTop, 
                behavior: to.name === from.name ? 'smooth' : 'auto'
            })
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        // {
        //     path:'/book-tour',
        //     name: 'book-tour',
        //     component: () => import('./views/BookTour.vue')
        // },
        {
            path: '/privacy-policy',
            name: 'privacy-policy',
            component: () => import('./views/PrivacyPolicy')
        },
        {
            path: '/adventures/diving',
            name: 'adventures.diving',
            component: () => import ('./views/tours/adventures/Diving.vue')
        },
        {
            path: '/adventures/paragliding',
            name: 'adventures.paragliding',
            component: () => import ('./views/tours/adventures/Paragliding.vue')
        },
        {
            path: '*',
            redirect: '/'
        }
    ]
})

export default router