<template>
  <div id="app">
    <transition name="fade">
      <router-view />
    </transition>

    <ProcessingSpinner v-if="processing" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ProcessingSpinner from "@/components/ProcessingSpinner.vue";

export default {
  name: "App",
  components: {
    ProcessingSpinner,
  },
  mounted() {
    this.getCheckpoints();
  },
  computed: {
    ...mapGetters(['processing'])
  },
  methods: {
    ...mapActions(["getCheckpoints"]),
  },
};
</script>

<style lang="scss">
$owl-image-path: "src/assets/images/";
@import "src/assets/css/bootstrap";
@import "src/assets/css/style";
@import "src/assets/css/animate";
@import "src/assets/css/style-magnific-popup";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i);
@import url(https://fonts.googleapis.com/css?family=Cabin:500,600,700);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
@import "src/assets/css/pe-icon-7-stroke";
@import "src/assets/css/helper";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
