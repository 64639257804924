import Vue from 'vue';
import App from './App.vue';

// import vueX
import store from "./store";

//import router
import router from './router';

// Minamilistic Spinners.
import "./animation/spinners";

import './mixins';
import './assets/js/bootstrap';
import './assets/js/jquery.scrollTo-min';
import './assets/js/jquery.magnific-popup.min';
import './assets/js/jquery.nav';
import './assets/js/plugins';

Vue.config.productionTip = false;

import VueSwal from 'vue-swal'

Vue.use(VueSwal)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
