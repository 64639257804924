export default {
  checkpoints: null,
  processing: false,
  tours: [
    {
        img: "https://cms.flexicms.tech/images/cms/mobile/1605697110.jpeg",
        title: "Diving",
        type: 'adventures',
        slug: 'diving'
    },
    {
        img: "https://cms.flexicms.tech/images/cms/mobile/1605870799.png",
        title: "Paragliding",
        type: 'adventures',
        slug: 'paragliding'
    },
    // {
    //     img: "https://cms.flexicms.tech/images/cms/mobile/1606043272.jpeg",
    //     title: "Ohrid Old Port",
    //     type: 'nature',
    //     slug: 'ohrid-old-port'
    // }
  ]
};
