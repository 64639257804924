import axios from 'axios';
const apiUrl = process.env.VUE_APP_API_URL;


export default {
  fetchCheckpoints() {
    return new Promise(resolve => {
      axios.get(apiUrl)
        .then(response => {
          resolve(response.data.checkpoints);
        })
    });
  }
};
