<template>
    <section class="section-culture" id="book-tour">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h2 class="section-title">Explore Ohrid's heritage</h2>

                    <p class="section-subtitle">
                        Discover over a 100 landmarks of cultural and natural heritage in
                        and around Ohrid.
                    </p>

                    <!-- <div>
                        <button
                            @click="setFilter('culture')"
                            class="btn sites-nav culture"
                            :disabled="loading"
                            :class="{ 'disabled-btn': loading }"
                        >
                            Culture
                        </button>
                        <button
                            @click="setFilter('adventures')"
                            class="btn sites-nav adventure"
                            :disabled="loading"
                            :class="{ 'disabled-btn': loading }"
                        >
                            Adventure
                        </button>
                        <button
                            @click="setFilter('nature')"
                            class="btn sites-nav nature"
                            :disabled="loading"
                            :class="{ 'disabled-btn': loading }"
                        >
                            Nature
                        </button>
                        <button
                            @click="setFilter('hospitality')"
                            class="btn sites-nav hospitality"
                            :disabled="loading"
                            :class="{ 'disabled-btn': loading }"
                        >
                            Hospitality
                        </button>
                    </div> -->
                </div>

                <div class="gallery-item-wrapper padding-top-30">
                    <div v-if="!loading">
                        <swiper
                            v-if="toursFiltered"
                            class="swiper"
                            ref="mySwiper"
                            :options="swiperOptions"
                        >
                            <swiper-slide v-for="(tour, index) in toursFiltered" :key="index">
                                <router-link :to="`/${tour.type}/${tour.slug}`">
                                    <div class="card-wrapper cursor-pointer">
                                        <div class="card-img">
                                            <img
                                                :src="tour.img"
                                                alt="showcase"
                                                class="gallery-show slider__image"
                                            />
                                        </div>

                                        <div class="card-description">
                                            <p><strong>{{ tour.title }}</strong></p>
                                        </div>
                                    </div>
                                </router-link>
                            </swiper-slide>

                            <div class="swiper-pagination" slot="pagination"></div>
                        </swiper>
                    </div>
                    <div v-else class="spinner__wrapper">
                        <spring-spinner
                            class="spinner__wrapper--item"
                            :animation-duration="2000"
                            :size="85"
                            color="#0f6cc3"
                        />
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
import carousel from "vue-owl-carousel";
import { mapGetters } from 'vuex'
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";

export default {
    props: ["sites"],

    components: {
        carousel,
        Swiper,
        SwiperSlide,
    },

    // mounted() {
    //     console.log(this.sites);
    // },

    data() {
        return {
            swiperOptions: {
                spaceBetween: 30,
                // centeredSlides: true,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                slidesPerView: 1,
                breakpoints: {
                    767: { slidesPerView: 2 },
                    992: { slidesPerView: 3 },
                    1200: { slidesPerView: 4 },
                },
                // Some Swiper option/callback...
            },
            modalData: {
                title: "",
                img: "",
                description: "",
            },
            filter: "",
            loading: false,
        };
    },

    computed: {
        ...mapGetters(['tours']),

        responsive() {
            return {
                0: {
                    items: 1,
                    nav: false,
                },
                600: {
                    items: 2,
                    nav: false,
                },
                1000: {
                    items: 4,
                    nav: false,
                    loop: true,
                },
            };
        },

        toursFiltered() {
            let tours = this.tours;

            switch (this.filter) {
                case "adventures":
                    tours = tours.filter( element => element.type === 'adventures' )
                    break;
                case "nature":
                    tours = tours.filter( element => element.type === 'nature' )
                    break;
                default:
                    return tours
            }

            return tours
        },
    },

    methods: {
        setFilter(filter) {
            this.loading = true;
            this.filter = filter;
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        },

        cardDescription(description) {
            return this.stripHtml(description).substring(0, 200);
        },

        stripHtml(html) {
            var temporalDivElement = document.createElement("div");
            temporalDivElement.innerHTML = html;

            return (
                temporalDivElement.textContent || temporalDivElement.innerText || ""
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  .card-img {
    display: flex;
    justify-content: center;

    img {
      width: 100%;
    }
  }

  .card-description {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.sites-nav {
  margin-left: 1rem;
  margin-right: 1rem;
  color: #fff;

  &:active {
    color: #fff;
  }

  &:focus {
    color: #fff;
  }

  &.culture {
    background-color: rgba($color: #a06faf, $alpha: 1);

    &:hover {
      background-color: #8e54a0;
      color: #fff;
    }
  }

  &.adventure {
    background-color: rgba($color: #7cbdf9, $alpha: 1);

    &:hover {
      background-color: #4094e2;
      color: #fff;
    }
  }

  &.nature {
    background-color: rgba($color: #70a84b, $alpha: 1);

    &:hover {
      background-color: #5ea32f;
      color: #fff;
    }
  }

  &.hospitality {
    background-color: rgba($color: #dc9c57, $alpha: 1);

    &:hover {
      background-color: #d68732;
      color: #fff;
    }
  }
}

.disabled-btn {
  cursor: not-allowed;
}

.spinner {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 350px;
  }
}

@media only screen and (max-width: 766px) {
  .sites-nav {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
</style>