<template>
  <div>
    <app-header ref="appHeader"></app-header>
    <home-section></home-section>
    <about-section></about-section>
    <features-section></features-section>
    <video-section></video-section>
    <book-tour-section :sites="checkpoints"></book-tour-section>
    <!-- <sites-section :sites="checkpoints"></sites-section> -->
    <download-section></download-section>
    <app-footer></app-footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppHeader from "../components/Header";
import AppFooter from "../components/Footer";
import HomeSection from "../components/Home";
import AboutSection from "../components/About";
import FeaturesSection from "../components/Features";
import VideoSection from "../components/Video";
import BookTourSection from '../components/BookTour.vue'
// import SitesSection from "../components/Sites";
import DownloadSection from "../components/Download";

export default {
  components: {
    AppHeader,
    AppFooter,
    HomeSection,
    AboutSection,
    FeaturesSection,
    VideoSection,
    BookTourSection,
    // SitesSection,
    DownloadSection,
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapGetters([
      "checkpoints"
    ])
  },
  methods: {
    handleScroll(event) {
      if (window.scrollY > 450) {
        this.$refs.appHeader.$refs.navbarTop.classList.add("opaque");
      } else {
        this.$refs.appHeader.$refs.navbarTop.classList.remove("opaque");
      }
    }
  },
};
</script>